import React from "react";
import { Link } from "react-router-dom";
import call from "../../images/icons/phone.png";


const Calltoaction = () => {
  return (
    <>
    <div className="sticky bottom-6 left-0 z-40 ">
      <Link
        to="tel:+917278221645"
        className="rounded-full   "
      >
        <img
          src={call}
          className="ml-5 rounded-3xl bg-blue-950 animate-bounce p-2 w-14 border"
          alt="wpicon"
        />
      </Link>

    </div>
    </>
  );
};

export default Calltoaction;
