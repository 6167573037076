import hero from "../../images/hero.jpg";
import React from "react";
import { Link } from "react-router-dom";


const Hero = () => {
  return (
    <>
      <div className=" mt-10  bg-white text-black font-f1  justify-center mx-auto w-full flex flex-col-reverse md:flex-row ">
        <div className="mt-4 md:mt-20">
          <h1 className="p-4 sm:mt-9 text-center md:text-left md:ml-20 font-bold text-3xl 2xl:text-8xl xl:text-8xl lg:text-7xl md:text-4xl xl:mt-6 sm:text-2xl">
            Looking for best interior designer in Kolkata?
          </h1>
          <p className="italic text-center md:text-left md:ml-20 align-middle 2xl:text-4xl xl:text-4xl lg:text-3xl md:text-xl 2xl:block xl:block lg:block md:block">
            Get the best interior design for your<br/> dream Home
          </p>
          <div className="mt-10 mb-7 md:ml-20 text-center md:text-left">
            <Link
              to="/contact"
              type="button"
              formAction="submit"
              class="p-4 text-black bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-blue-800 shadow-lg shadow-yellow-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
            >
              Visit Our Gallery
            </Link>
            <Link
              to="/contact"
              type="button"
              formAction="submit"
              class="text-black bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-blue-800 shadow-lg shadow-yellow-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
            >
              Get a Quote
            </Link>
          </div>
        </div>
        <div className="top-5 w-full mt-24 bg-gradient-to-l from-lime-200 to-transparent">
          <img
            className=" float-right md:mt-12 md:h-2/3 lg:h-5/6 lg:mt-12   z-30 "
            src={hero}
            alt="HeroBanner"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
