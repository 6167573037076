import React from "react";
import Footer from "../component/footer";
import Hero from "../component/Homepage/hero";
import MiniHero from "../component/Homepage/minihero";
import Services from "../component/Homepage/services";
import Testimonials from "../component/Homepage/testimonials";
import OurWorks from "../component/Homepage/ourworks";
import Query from "../component/Homepage/query";
import Process from "../component/Homepage/process";
import Transition from "../component/transition";
import PageAnimation from "../component/pageAnimation";
import Calltoaction from "../component/Homepage/calltoaction";


const Home = () => {
  return (
    <>
   <PageAnimation>
      <Hero />
      <MiniHero />
      <Services />
      <OurWorks />
      <Process />
      <Testimonials />
      <Query />
      <Footer />
      <Calltoaction />
      {/* <Wpchat /> */}
   </PageAnimation>
    </>
  );
};
export default Transition(Home);
