import React from "react";
import { Link } from "react-router-dom";


const Query = () => {
  return (
    <>
      <div className="bg-indigo-900 text-white shadow-lg animate-pulse mt-10 h-14 w-9/12 rounded-full drop-shadow-2xl mx-auto">
      <p className="text-center p-4"><Link to="/contact">Subscribe to Newsletter</Link></p>
      </div>
    </>
  );
};

export default Query;
