"use client";
import { Carousel } from "flowbite-react";
import React from "react";
import { createTheme } from "flowbite-react";
import undln from "../../images/underlines/underline.png";
import { Link } from "react-router-dom";

const carouselTheme = createTheme({
  root: {
    base: "relative h-full w-full",
    leftControl:
      "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
    rightControl:
      "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
  },
  indicators: {
    active: {
      off: "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
      on: "bg-yellow-500",
    },
    base: "h-3 w-3 rounded-full",
    wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
  },
  item: {
    base: "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
    wrapper: {
      off: "w-full flex-shrink-0 transform cursor-default snap-center",
      on: "w-full flex-shrink-0 transform cursor-grab snap-center",
    },
  },
  control: {
    base: "inline-flex h-8 w-8 items-center justify-center rounded-full opacity-10  group-focus:outline-none  group-focus:ring-yellow-500 dark:bg-yellow-400 dark:group-hover:bg-yellow-500 dark:group-focus:ring-yellow-400 sm:h-10 sm:w-10",
    icon: "h-5 w-5 text-black dark:text-gray-800 sm:h-6 sm:w-6",
  },
  scrollContainer: {
    base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
    snap: "snap-x",
  },
});

const Testimonials = () => {
  return (
    <>
      <div className="bg-cyan-950">
        <h1 className="text-center text-2xl pt-10 text-white">
          What Our Customers say about us
        </h1>
        <img src={undln} alt="underline" className="mx-auto h-6" />
      </div>

      <div className="mx-auto h-72">
        <Carousel
          theme={carouselTheme}
          slideInterval={3000}
          className=" bg-cyan-950"
        >
          <div className="text-white ">
            <p className="text-center text-2xl text-white">Pallawi Priya</p>
            <p className="italic text-center font-bold text-white">
              Sayan and his team did a great job in transforming our house. He
              had designed our house so beautifully. I had a amazing experience
              with him highly recommended.
            </p>
          </div>
          <div>
            <p className="text-center text-xl text-white">Jaya Sharma</p>
            <p className="text-center italic font-bold text-white">
              Professional work. Got a lot of help and design suggestions as
              expected. Would recommend for home or office interiors.
            </p>
          </div>
          <div>
            <p className="text-center text-xl text-white">Rajdeep Ghosh</p>
            <p className="text-center italic font-bold text-white">
              We took a contract with DIC to decorate our chember in Newtown,
              Kolkata. Sayan is very smart & intelligent young man. He & his
              team did a really good job. Best of luck for future! - Happy
              Consumer!
            </p>
          </div>
          <div>
            <p className="text-center text-xl text-white">Saikat Pal</p>
            <p className="text-center italic font-bold text-white">
              They are very professional and best in any kind of Interior
              Designing work. From product to service, you can get all the
              proper knowledge and quality service.
            </p>
          </div>
          <div>
            <p className="text-center text-xl text-white">
              Priyanka Chakraborty
            </p>
            <p className="text-center italic font-bold text-white">
              The person is very helpfull..the quality of the materials are very
              good..they are very much professional..on time delivery and humble
              in nature.. All over i love their work.. in one work you can trust
              this on your blind eye..
            </p>
          </div>
        </Carousel>
      </div>
      <div className="text-center mt-4">
        <Link
          to="https://g.co/kgs/aDsqQka"
          className="bg-amber-300 font-bold shadow-md p-2 rounded-lg"
        >
          Send Us your valuable review
        </Link>
      </div>
    </>
  );
};

export default Testimonials;
