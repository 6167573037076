import React from "react";
import transition from "../component/transition";
import Footer from "../component/footer";
import ul from "../images/underlines/underline.png";
import Calltoaction from "../component/Homepage/calltoaction";

const Contact = () => {
  return (
    <>
      <div className=" mt-10 h-80 text-center content-center bg-gradient-to-b from-amber-100 to-transparent">
        <h1 className="font-extrabold mt-4 text-3xl text-yellow-500">
          Contact Us
        </h1>
        <p className="text-black">Have any query, Submit your messages</p>
        <img src={ul} alt="underline" className="h-8 mx-auto" />
      </div>
      <form action="https://formspree.io/f/mbjnbdvg" method="POST">
        <div class="container p-4 gap-6 mb-6 md:grid-cols-2 content-center flex-col m-auto mt-10 ">
          <div>
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Please type your Name"
              required
            />
          </div>
          <div class="mb-6">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Please type your Email"
              required
            />
          </div>
          <div>
            <label
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Phone number
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Please type your Mobile Number"
              required
            />
          </div>
          <div class="mb-6">
            <label
              for="large-input"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Type your messages below
            </label>
            <input
              type="text"
              id="large-input"
              name="large-input"
              class="block w-full p-4 h-24  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:top-1"
              placeholder="Type your Messages"
            />
          </div>
          <input
            type="submit"
            formAction="https://formspree.io/f/mleqvgyo"
            class="text-black bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-blue-800 shadow-lg shadow-yellow-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 "
            value="Submit"
          />
        </div>
      </form>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14746.650649189036!2d88.339481!3d22.4793076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0271847d00466d%3A0xd7229c0089e56a8a!2sDEY%20INFINITE%20CONSTRUCTION%20%7C%20INTERIOR%20DESIGNER!5e0!3m2!1sen!2sin!4v1715405787160!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
      <Footer />
      <Calltoaction />
    </>
  );
};

export default transition(Contact);
