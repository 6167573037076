import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import wpicon from "../images/icons/whatsapp.png";
import callicon from "../images/icons/phone.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="bg-white text-black fixed w-full top-0 z-20">
      <div className="bg-black h-6 w-full text-white flex text-center justify-center">
        <Link to="tel:+917278221645" className=" text-sm ml-2 animate-pulse">Call : +91 7278221645 | </Link>
        <Link to="mailTo:deyinfiniteconstruction@gmail.com" className="text-sm ml-2 animate-pulse">Email : deyinfiniteconstruction@gmail.com</Link>
      </div>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={logo} className="h-8 sm:h-12 md:h-14 lg:h-16 2xl:h-20" alt="Flowbite Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-wrap">
              Dey Infinite construction
              <p className="font-thin -mt-2"> Interior Designer</p>
            </span>
          </Link>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-7 border-l-lime-600  ">
          <Link
            to="tel:+917278221645"
            type="button"
            formAction="submit"
            className=""
            >
          <img className="h-8 bg-blue-900 animate-bounce rounded-xl" src={callicon} alt="call icon" />
          </Link>
          <Link
            to="https://wa.me/917278221645"
            type="button"
            formAction="submit"
            className=" "
          >
            <img className="h-8 animate-bounce" src={wpicon} alt="whatsapp icon" />
          </Link>
          </div>
          

            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 transition-colors duration-300"
              aria-controls="navbar-cta"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-5 h-5 ${isMenuOpen ? "text-green-300" : "text-gray-500"}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 transition-all duration-300 ${
              isMenuOpen ? "block" : "hidden"
            }`}
            id="navbar-cta"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
              <li>
                <Link
                  to="/"
                  className={`block py-2 px-3 md:p-0 rounded ${
                    location.pathname === "/"
                      ? "text-blue-700"
                      : "hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  } ${isMenuOpen ? "text-black" : ""}`}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery"
                  className={`block py-2 px-3 md:p-0 rounded ${
                    location.pathname === "/gallery"
                      ? "text-blue-700"
                      : "hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  } ${isMenuOpen ? "text-black" : ""}`}
                  aria-current="page"
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className={`block py-2 px-3 md:p-0 rounded ${
                    location.pathname === "/about"
                      ? "text-blue-700"
                      : "hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  } ${isMenuOpen ? "text-black" : ""}`}
                  aria-current="page"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className={`block py-2 px-3 md:p-0 rounded ${
                    location.pathname === "/contact"
                      ? "text-blue-700"
                      : "hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  } ${isMenuOpen ? "text-black" : ""}`}
                  aria-current="page"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
