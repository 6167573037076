import React from "react";
import Footer from "../component/footer";
import Workprocess from "../component/workProcess";
import transition from "../component/transition";
import ul from "../images/underlines/underline.png";
import img1 from "../images/all_images/1-min.jpeg";
import img2 from "../images/all_images/2-min.jpeg";
import img3 from "../images/all_images/3-min.png";
import img4 from "../images/all_images/4-min.png";
import Calltoaction from "../component/Homepage/calltoaction.js";



const About = () => {
  return (
    <>
     
      <section className=" mt-10 h-80 text-center content-center bg-gradient-to-b from-amber-100">
        <h1 className="font-extrabold text-3xl mt-6 text-yellow-500">
          About Us
        </h1>
        <p className="text-amber-950">15 Years into this Business</p>
        <img src={ul} alt="underline" className="h-10 mx-auto" />

      </section>
      <div className="">
        <article className="container m-auto lg:flex md:flex-wrap   ">
          <div className=" gap-4">
            <img
              className="lg:mt-12  md:w-full max-w-full rounded-3xl p-4"
              src="https://lh3.googleusercontent.com/p/AF1QipPnJFH6A3mC9i1Ktuqbpxx4Xvv5GfHsf9p5e6O6=s680-w680-h510"
              alt=""
            />

            <div className="xl:grid xl:grid-cols-4 xl:gap-4 lg:grid lg:grid-cols-3 md:grid md:grid-cols-4 sm:grid sm:grid-cols-4 grid grid-cols-2 m-auto justify-center">
              <div>
                <img
                  className="w-48 h-56 p-2 rounded-2xl object-cover "
                  src={img1}
                  alt="show-img"
                />
              </div>
              <div>
                <img
                  className="w-48 h-56 p-2 rounded-2xl bg-cover object-cover "
                  src={img2}
                  alt="show-img"
                />
              </div>
              <div>
                <img
                  className="w-48 h-56 p-2 rounded-2xl object-cover "
                  src={img3}
                  alt="show-img"
                />
              </div>
              <div>
                <img
                  className="w-48 h-56 p-2 rounded-2xl object-cover"
                  src={img4}
                  alt="show-img"
                />
              </div>
            </div>
          </div>

          <div className="ml-4 flex-1">
            <p className="mb-5 mt-10 first-letter:text-7xl first-letter:font-bold leading-loose">
              <span className="p-2">A</span>re you seeking the expertise of a
              seasoned interior designer in Kolkata to breathe new life into
              your home or office space? Look no further than{" "}
              <span className="font-bold">
                DEY INFINITE CONSTRUCTION INTERIOR DESIGNER
              </span>{" "}
              , the pinnacle of reliability and innovation in interior design
              services. With a legacy of excellence, we stand as Kolkata's most
              reputable and trusted interior design firm, renowned for our
              unwavering commitment to delivering unparalleled quality and
              creativity. At{" "}
              <span className="font-bold">
                DEY INFINITE CONSTRUCTION INTERIOR DESIGNER
              </span>
              , we pride ourselves on our ability to exceed expectations,
              meticulously curating spaces that epitomize luxury, functionality,
              and style. Our team of seasoned professionals possesses a wealth
              of experience and expertise, ensuring that every project is
              executed with precision and finesse. What sets us apart is our
              unwavering dedication to client satisfaction and our relentless
              pursuit of perfection. We understand that each space is unique,
              and our personalized approach ensures that your vision is brought
              to life seamlessly, within your specified budget and timeline.
              Whether you're looking to revitalize your residential abode,
              elevate your corporate headquarters, or redefine your commercial
              establishment,{" "}
              <span className="font-bold">
                DEY INFINITE CONSTRUCTION INTERIOR DESIGNER
              </span>{" "}
              is your trusted partner every step of the way. Our commitment to
              excellence is evident in every detail, as we strive to create
              interiors that inspire and captivate, leaving a lasting impression
              on all who experience them. Experience the difference with{" "}
              <span className="font-bold">
                DEY INFINITE CONSTRUCTION INTERIOR DESIGNER
              </span>
              , where innovation meets sophistication, and every space is
              transformed into a masterpiece of design excellence.
            </p>
          </div>
        </article>
      </div>
      <Workprocess />
      <Footer />
    <Calltoaction />
    </>
  );
};

export default transition(About);
