import React from "react";
import { motion } from "framer-motion";

const PageAnimation = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }} // Initial opacity and y position
      animate={{ opacity: 1, y: 10 }} // Final opacity and y position
      transition={{ duration: 2, ease: "easeInOut" }} // Transition duration and easing
    >
      {children}
    </motion.div>
  );
};

export default PageAnimation;
