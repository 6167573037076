import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Dey Infinite Construction
            </span>
            <p>
              Address :55/3/1, Naskar Para Rd,
              <br /> Jiban Mohini Ghosh Park,
              <br /> Kolkata, West Bengal 700041
              <br /> Contact No. : +91 72782 21645
              <br/> Email : deyinfiniteconstruction@gmail.com

            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <h1 className=" text-xl font-semibold">Follow us on<br/>
            <Link to="https://www.facebook.com/deyinfiniteconstruction?mibextid=JRoKGi" className="font-thin text-sm">Facebook</Link><br/>
            <Link to="https://www.instagram.com/deyinfiniteconstruction?igsh=MTB1bXdiM3Zzd2VwOA%3D%3D&utm_source=qr" className="font-thin text-sm">Instagram</Link></h1>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024 Dey Infinite Construction. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            {/* No content */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
