import { motion } from "framer-motion";

const Transition = (OgComponent) =>{
    return () =>(
        <>
            <OgComponent />
            <motion.div 
                className="slide-in fixed top-0 left-0 w-full h-screen bg-white origin-bottom"
                initial = {{ scaleY:1 }}
                animate= {{ scaleY:0 }}
                exit={{scaleY : 1}}
                transition={{duration: 1, ease:[ 0.22,1,0.36,1 ] }}
            />
            <motion.div
                className="slide-in fixed top-0 left-0 w-full h-screen bg-white origin-top"
                initial = {{ scaleY:1 }}
                animate= {{ scaleY:0 }}
                exit={{scaleY : 1}}
                transition={{duration: 1, ease:[ 0.22,1,0.36,1 ] }}
            />
        </>
    );
};


export default Transition;