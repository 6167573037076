import imgpr from "../../images/IMG_1598.jpg";
import undln from "../../images/underlines/underline.png";

const Process = () => {
  return (
    <>
      <h1 className="text-center font-bold text-2xl mt-16">How we work</h1>
      <img src={undln} alt="underline" className="mx-auto h-6" />
      <div className="container justify-center m-auto 2xl:flex xl:flex lg:flex md:flex  mt-4">
        <div className="mainDiv justify-center m-auto 2xl:block xl:block lg:block flex-col ml-4 md:flex sm:flex  ">
          <div className="flex">
            <div className="div1 p-3 bg-green-500 w-14 h-14 rounded-full mb-5"><p className="text-center mt-1 font-extrabold text-white">1</p></div>
            <p className="tag text-2xl ml-[15px] mt-0 font-bold">
              Initial Consultation
              <br />
              <span className="tagp text-sm font-thin ">Discuss client preferences, budget, and timeline.</span>
            </p>
          </div>
          <div className="line bg-slate-600 w-[2px] h-8 md:h-14 ml-7 mb-4"></div>

          <div className="flex">
            <div className="div2 p-3 bg-green-500 w-14 h-14 rounded-full mb-5"><p className="text-center mt-1 font-extrabold text-white">2</p></div>
            <p className="tag text-2xl ml-[15px] mt-0 font-bold">
              Concept Development
              <br />
              <span className="tagp text-sm font-thin ">Brainstorm design ideas and create visual concepts.</span>
            </p>
          </div>
          <div className="line bg-slate-600 w-[2px] h-8 md:h-14 ml-7 mb-4"></div>

          <div className="flex">
            <div className="div3 p-3 bg-green-500 w-14 h-14 rounded-full mb-5"><p className="text-center mt-1 font-extrabold text-white">3</p></div>
            <p className="tag text-2xl ml-[15px] mt-0 font-bold">
              Design Proposal
              <br />
              <span className="tagp text-sm font-thin ">Present design concepts and material samples to the client.</span>
            </p>
          </div>
        </div>

        <div className="">
          <img className="h-[400px] mb-7 2xl:ml-72 xl:ml-20 lg:ml-2 "
            src={imgpr}
            alt=""
            srcset=""
          />
        </div>
      </div>
    </>
  );
};

export default Process;
