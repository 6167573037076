import React from "react";
import assurance from "../../images/icons/assurance.png";
import solution from "../../images/icons/solution.png";
import personalised from "../../images/icons/personalized.png";


const MiniHero = () => {
  return (
    <>
      <div className="bg-gradient-to-t from-10% from-white bg-indigo-100">
        <div className="container justify-center flex m-auto space-x-1 md:space-x-2 lg:space-x-8 xl:space-x-24 2xl:space-x-40 p-2">
          <div className="shadow-md shadow-green-200 rounded-2xl w-64">
            <img src={assurance} className="h-20 md:h-28 p-4 m-auto" alt="Elevating spaces"/>
            <h1 className="text-center text-black font-extralight text-sm md:text-xl md:p-8 shadow-white">Elevating Spaces, Exceeding Expectations</h1>
          </div>
          <div className="shadow-md shadow-green-200 rounded-2xl w-64">
            <img src={solution} className="h-20 md:h-28 p-4 m-auto" alt="crafting dream" />
            <h1 className="text-center text-black font-extralight text-sm md:text-xl md:p-8 shadow-white">Crafting Dream Interiors, Setting the Standard</h1>
          </div>
          <div className="shadow-md shadow-green-200 rounded-2xl w-64">
            <img src={personalised} className="h-20 md:h-28 p-4 m-auto" alt="design excellence" />
            <h1 className="text-center text-black font-extralight text-sm md:text-xl md:p-8 shadow-white">Design Excellence, Tailored for You</h1>
          </div>
        </div>
        </div>
     </>
  );
};

export default MiniHero;
