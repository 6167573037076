import React from "react";
import ul from "../../images/underlines/underline.png";

const Services = () => {
  return (
    <>
      <div className="h-auto pb-7">
        <h1 className="text-center text-2xl font-extrabold text-black">
          <br />
          Our Services
        </h1>
        <img src={ul} alt="designed underline" className="mx-auto h-6" />
        <div className="container w-full mx-auto justify-center md:flex relative z-10">
          <div className="rounded-full bg-gradient-to-b from-amber-500 to-yellow-200 w-64 md:w-56 h-16 mt-8 flex items-center justify-center text-center mx-auto">
            <p className="font-extrabold text-2xl text-amber-950">Interior Designing</p>
          </div>
          <div className="rounded-full bg-gradient-to-t from-yellow-200 via-amber-500 to-yellow-200 w-64 md:w-56 h-16 mt-8 flex items-center justify-center text-center mx-auto">
            <p className="font-extrabold text-2xl text-amber-950">Exterior Designing</p>
          </div>
          <div className="rounded-full bg-gradient-to-b from-yellow-200 to-amber-500 w-64 md:w-56 h-16 mt-8 flex items-center justify-center text-center mx-auto">
            <p className="font-extrabold text-2xl text-amber-950">Architect Designing</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
