import React from "react";
import { Link } from "react-router-dom";
import img1 from "../images/all_images/initial_consultation.jpg";
import img2 from "../images/all_images/discussion.jpg";
import img3 from "../images/all_images/implementation.jpg";


const WorkProcess = () => {
  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-center text-4xl md:text-7xl font-extrabold mb-10">How we Work!</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* First card */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-center mb-4">First Step</h2>
            <Link to="/" className="block mb-4">
              <img
                src={img1}
                alt="Initial Consultation"
                className="w-full h-48 object-cover rounded-lg"
              />
            </Link>
            <h3 className="text-xl font-semibold mb-2 text-center">
              <Link to="/" className="hover:text-blue-600">Initial Consultation</Link>
            </h3>
            <p className="text-base text-gray-700 dark:text-gray-400 text-center">
              Schedule a meeting with our expert designers to discuss your vision, requirements, and budget. We'll listen carefully to your ideas and provide professional advice to bring your dream space to life.
            </p>
          </div>
        </div>

        {/* Second card */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-center mb-4">Second Step</h2>
            <Link to="/" className="block mb-4">
              <img
                src={img2}
                alt="Concept & Design Development"
                className="w-full h-48 object-cover rounded-lg"
              />
            </Link>
            <h3 className="text-xl font-semibold mb-2 text-center">
              <Link to="/" className="hover:text-blue-600">Concept & Design Development</Link>
            </h3>
            <p className="text-base text-gray-700 dark:text-gray-400 text-center">
              Based on our discussion, we'll create concept boards and design drafts tailored to your preferences. This stage involves selecting color schemes, furniture styles, materials, and layout plans to match your aesthetic and functional needs.
            </p>
          </div>
        </div>

        {/* Third card */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6">
            <h2 className="text-2xl font-bold text-center mb-4">Third Step</h2>
            <Link to="/" className="block mb-4">
              <img
                src={img3}
                alt="Implementation & Execution"
                className="w-full h-48 object-cover rounded-lg"
              />
            </Link>
            <h3 className="text-xl font-semibold mb-2 text-center">
              <Link to="/" className="hover:text-blue-600">Implementation & Execution</Link>
            </h3>
            <p className="text-base text-gray-700 dark:text-gray-400 text-center">
              Once the design is finalized, our skilled team will commence the implementation phase. We'll coordinate with contractors, oversee construction or renovation work, source furnishings and decor, and ensure every detail aligns with the approved design plan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
