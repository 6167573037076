import React, { useState } from "react";
import Modal from "react-modal";
import Transition from "../component/transition";
import Footer from "../component/footer";
import undln from "../images/underlines/underline.png";
import Calltoaction from "../component/Homepage/calltoaction.js";

// Importing images from 1 to 39
import img1 from "../images/all_images/1-min.jpeg";
import img2 from "../images/all_images/2-min.jpeg";
import img3 from "../images/all_images/3-min.png";
import img4 from "../images/all_images/4-min.png";
import img5 from "../images/all_images/5-min.png";
import img6 from "../images/all_images/6-min.png";
import img7 from "../images/all_images/7-min.jpg";
import img8 from "../images/all_images/8-min.jpg";
import img9 from "../images/all_images/9-min.jpg";
import img10 from "../images/all_images/10-min.jpg";
import img11 from "../images/all_images/11-min.png";
import img12 from "../images/all_images/12-min.png";
import img13 from "../images/all_images/13-min.png";
import img14 from "../images/all_images/14-min.png";
import img15 from "../images/all_images/15-min.png";
import img16 from "../images/all_images/16-min.png";
import img17 from "../images/all_images/17-min.png";
import img18 from "../images/all_images/18-min.png";
import img19 from "../images/all_images/19-min.jpg";
import img20 from "../images/all_images/20-min.png";
import img21 from "../images/all_images/21-min.png";
import img22 from "../images/all_images/22-min.jpeg";
import img23 from "../images/all_images/23-min.jpeg";
import img24 from "../images/all_images/24-min.jpeg";
import img25 from "../images/all_images/25-min.jpeg";
import img26 from "../images/all_images/26-min.jpeg";
import img27 from "../images/all_images/27-min.jpeg";
import img28 from "../images/all_images/28-min.jpeg";
import img29 from "../images/all_images/29-min.jpeg";
import img30 from "../images/all_images/30-min.jpeg";
import img31 from "../images/all_images/31-min.jpeg";
import img32 from "../images/all_images/32-min.jpg";
import img33 from "../images/all_images/33-min.jpg";
import img34 from "../images/all_images/34-min.png";
import img35 from "../images/all_images/35-min.png";
import img36 from "../images/all_images/36-min.png";
import img37 from "../images/all_images/37-min.png";
import img38 from "../images/all_images/38-min.png";
import img39 from "../images/all_images/39-min.png";

Modal.setAppElement('#root');

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (imgSrc) => {
    setCurrentImage(imgSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10,
    img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
    img21, img22, img23, img24, img25, img26, img27, img28, img29, img30,
    img31, img32, img33, img34, img35, img36, img37, img38, img39
  ];

  return (
    <>
      <div className="mt-10 bg-gradient-to-b from-amber-100 to-transparent">
        <div className="h-80 text-center content-center">
          <h1 className=" mt-8 text-3xl text-amber-950 text-center font-extrabold  hover:text-transparent bg-clip-text bg-gradient-to-r from-yellow-50 via-amber-100 to-yellow-800 duration-300">
            Our work's Showcase
          </h1>
          <p className="text-center text-lg">
            Where imagination meets innovation, transforming spaces into
            captivating stories.
          </p>
          <img src={undln} alt="underline" className="h-8 mx-auto" />
        </div>
      </div>
      <div>
        <div className="container mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 justify-center ">
          {images.map((imgSrc, index) => (
            <div key={index}>
              <img
                className="h-64 w-52 md:w-64 lg:w-72 2xl:w-80 2xl:h-72 rounded-lg cursor-pointer"
                src={imgSrc}
                alt=""
                onClick={() => openModal(imgSrc)}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Calltoaction />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div className="relative bg-white p-4 rounded-lg shadow-lg">
          <button onClick={closeModal} className="absolute top-2 right-2 text-black text-2xl">
            &times;
          </button>
          <img src={currentImage} alt="Modal Content" className="max-h-screen max-w-full" />
        </div>
      </Modal>
    </>
  );
};

export default Transition(Gallery);
