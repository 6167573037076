import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import img1 from "../../images/recent_works/1.png";
import img2 from "../../images/recent_works/2.png";
import img3 from "../../images/recent_works/3.png";
import img4 from "../../images/recent_works/4.png";
import img5 from "../../images/recent_works/5.png";
import img6 from "../../images/recent_works/6.png";
import img7 from "../../images/recent_works/7.jpeg";
import img8 from "../../images/recent_works/8.jpeg";
import undln from "../../images/underlines/underline.png";

Modal.setAppElement('#root'); // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

const OurWorks = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (imgSrc) => {
    setCurrentImage(imgSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <>
      <div className="">
        <h1 className="text-center text-2xl font-extrabold">
          <br />
          Our Recent Works
        </h1>
        <img src={undln} alt="underline" className="h-6 mx-auto" />
      </div>

      <div className="container mt-6 mx-auto grid gap-2 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:gap-y-9 xl:gap-y-20">
        {[img1, img2, img3, img4, img5, img6, img7, img8].map((imgSrc, index) => (
          <div key={index} className="mx-auto">
            <img
              className="h-52 lg:h-64 w-44 md:w-60 lg:w-72 object-cover rounded-lg shadow-mg shadow-yellow-200 hover:scale-110 transition-all cursor-pointer"
              src={imgSrc}
              alt="our works"
              onClick={() => openModal(imgSrc)}
            />
          </div>
        ))}
      </div>

      <div className="text-center p-9 bg-white">
        <Link
          to="/gallery"
          className="mr-5 w-56 bg-amber-300 shadow-md text-amber-950 font-bold p-2 rounded-lg"
        >
          See more of our works
        </Link>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div className="relative bg-white p-4 rounded-lg shadow-lg">
          <button onClick={closeModal} className="absolute top-2 right-2 text-black text-2xl">
            &times;
          </button>
          <img src={currentImage} alt="Modal Content" className="max-h-screen max-w-full" />
        </div>
      </Modal>
    </>
  );
};

export default OurWorks;
